import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Fuel from './fuel.js';
import Electric from './electric.js';

function Expenses() {
    const [people, setPeople] = useState(2);
    const [distance, setDistance] = useState(10);
    const [fuelOrElectric, setFuelOrElectric] = useState(() => {
        const saved = localStorage.getItem("fuelOrElectric");
        const fuelOrElectric = JSON.parse(saved);
        return fuelOrElectric || "fuel";
    });

    useEffect(() => {
        localStorage.setItem("fuelOrElectric", JSON.stringify(fuelOrElectric));
    }, [fuelOrElectric]);

    return (
        <div>
            <h1>Bensat</h1>
            <Form className="container" onSubmit={()=>{}}>
                <Form.Group className="mb-3" controlId="formHlo">
                    <Form.Label>Henkilöitä</Form.Label>
                    <Form.Control type="number" value={people} onChange={e => setPeople(e.target.value)} />
                    <Form.Text className="text-muted">Kuinka monta henkilöä mukana?</Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formDistance">
                    <Form.Label>Matkan pituus</Form.Label>
                    <Form.Control type="number" value={distance} onChange={e => setDistance(e.target.value)} />
                    <Form.Text className="text-muted">Etäisyys km</Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formFuelOrElectric">
                    <div>
                        <Form.Check className="form-check-inline"
                            value="fuel"
                            type="radio"
                            label="Bensa"
                            onChange={e => setFuelOrElectric(e.target.value)}
                            checked={fuelOrElectric === "fuel"}
                        />
                        <Form.Check className="form-check-inline"
                            value="electric"
                            type="radio"
                            label="Sähkö"
                            onChange={e => setFuelOrElectric(e.target.value)}
                            checked={fuelOrElectric === "electric"}
                        />
                    </div>
                    <Form.Text className="text-muted">Bensa- vai sähköauto</Form.Text>
                </Form.Group>
                {fuelOrElectric === "fuel"
                    ? <Fuel people={people} distance={distance} />
                    : <Electric people={people} distance={distance} />
                }
                
            </Form>
        </div>
    );
}

export default Expenses;