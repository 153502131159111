import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Helmet} from 'react-helmet';
import Expenses from './Expenses.js';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Kulutuslaskuri</title>
        <meta name="description" content="Helppo kulutuslaskuri kulujen jakamiseen" />
        <meta name="og:title" content="Kulutuslaskuri" />
        <meta name="og:description" content="Helppo kulutuslaskuri kulujen jakamiseen" />
      </Helmet>
      <Expenses />
    </div>
  );
}

export default App;
