import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

function Fuel({distance, people}) {
    const [price, setPrice] = useState(0);
    const [mileage, setMileage] = useState(() => {
        const saved = localStorage.getItem("mileage");
        const initialMileage = JSON.parse(saved);
        return initialMileage || 5.5;
    });
    
    useEffect(() => {
        fetch("/.netlify/functions/fuelPrice")
            .then(response => response.json())
            .then(data => {
                setPrice(data.data);
            });

    }, []);

    useEffect(() => {
        localStorage.setItem("mileage", JSON.stringify(mileage));
    }, [mileage]);

    return (
        <>
                <Form.Group className="mb-3" controlId="formPrice">
                    <Form.Label>Bensan hinta</Form.Label>
                    <Form.Control type="number" value={price} onChange={e => setPrice(e.target.value)} />
                    <Form.Text className="text-muted">€ / litra</Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formMileage">
                    <Form.Label>Kulutus</Form.Label>
                    <Form.Control type="number" value={mileage} onChange={e => setMileage(e.target.value)} />
                    <Form.Text className="text-muted">l / 100km</Form.Text>
                </Form.Group>
                <div>
                    <h2>Hinta / hlö</h2>
                    <span>{Number.parseFloat(price * distance * (mileage/100) / people).toFixed(2)} €</span>
                </div>
        </>
    )
}

export default Fuel;