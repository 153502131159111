import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

function Electric({distance, people}) {
    const [price, setPrice] = useState(() => {
        const saved = localStorage.getItem("electricPrice")
        const initialPrice = JSON.parse(saved);
        return initialPrice || 0;
    });
    const [mileage, setMileage] = useState(() => {
        const saved = localStorage.getItem("electricMileage");
        const initialMileage = JSON.parse(saved);
        return initialMileage || 0;
    });
    
    useEffect(() => {
        localStorage.setItem("electricMileage", JSON.stringify(mileage));
    }, [mileage]);

    useEffect(() => {
        localStorage.setItem("electricPrice", JSON.stringify(price));
    }, [price]);

    return (
        <>
                <Form.Group className="mb-3" controlId="formPrice">
                    <Form.Label>Sähkön hinta</Form.Label>
                    <Form.Control type="number" value={price} onChange={e => setPrice(e.target.value)} />
                    <Form.Text className="text-muted">€ / kWh</Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formMileage">
                    <Form.Label>Kulutus</Form.Label>
                    <Form.Control type="number" value={mileage} onChange={e => setMileage(e.target.value)} />
                    <Form.Text className="text-muted">kWh / 100km</Form.Text>
                </Form.Group>
                <div>
                    <h2>Hinta / hlö</h2>
                    <span>{Number.parseFloat(price * distance * (mileage/100) / people).toFixed(2)} €</span>
                </div>
        </>
    )
}

export default Electric;